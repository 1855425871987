/**
 *  Plan constant module file
 **/

export const MODULE_NAME = 'plan'

export const ACTIONS = {
  LOAD_PLAN: 'LOAD_PLAN',
  CREATE_PLAN: 'CREATE_PLAN',
  UPDATE_PLAN: 'UPDATE_PLAN',
  DELETE_PLAN: 'DELETE_PLAN',
  LOAD_CUSTOM_GEOBOUNDARY: 'LOAD_CUSTOM_GEOBOUNDARY',
  CREATE_CUSTOM_GEOBOUNDARY: 'CREATE_CUSTOM_GEOBOUNDARY',
  UPDATE_CUSTOM_GEOBOUNDARY: 'UPDATE_CUSTOM_GEOBOUNDARY',
  DELETE_CUSTOM_GEOBOUNDARY: 'DELETE_CUSTOM_GEOBOUNDARY',
  LOAD_ASSETS: 'LOAD_ASSETS',
  LOAD_EXCLUDE_ASSETS: 'LOAD_EXCLUDE_ASSETS',
  LOAD_POIS_LAYER: 'LOAD_POIS_LAYER',
  UPDATE_ASSET_LAYER: 'UPDATE_ASSET_LAYER',
  UPDATE_ASSETS_INITIAL_STATS: 'UPDATE_ASSETS_INITIAL_STATS',
  UPDATE_AUDIENCE_DELIVERY: 'UPDATE_AUDIENCE_DELIVERY',
  SET_ASSETS_TOTAL_STATS: 'SET_ASSETS_TOTAL_STATS',
  LOAD_PACKAGES: 'LOAD_PACKAGES',
  REMOVE_PACKAGE_SELECTION: 'REMOVE_PACKAGE_SELECTION',
  SET_PACKAGE_SELECTION: 'SET_PACKAGE_SELECTION',
  UPDATE_ASSETS_FACING_ORIENTATION_ICONS:
    'UPDATE_ASSETS_FACING_ORIENTATION_ICONS',
  UPDATE_PACKAGE_STATE: 'UPDATE_PACKAGE_STATE',
  UPDATE_PACKAGE_AVAILABILITY: 'UPDATE_PACKAGE_AVAILABILITY',
  UPDATE_PACKAGE_PRICE: 'UPDATE_PACKAGE_PRICE',
  UPDATE_POIS_BUFFER_VISIBILITY: 'UPDATE_POIS_BUFFER_VISIBILITY',
  UPDATE_POIS_VISIBILITY: 'UPDATE_POIS_VISIBILITY',
  UPDATE_BOUNDARIES_VISIBILITY: 'UPDATE_BOUNDARIES_VISIBILITY',
  UPDATE_ASSETS_IDS_LAYER_VISIBILITY: 'UPDATE_ASSETS_IDS_LAYER_VISIBILITY',
  UPDATE_COLORIZE_ASSETS: 'UPDATE_COLORIZE_ASSETS',
  UPDATE_BASEMAP: 'UPDATE_BASEMAP',
}

export const GETTERS = {
  GET_FLAVOUR: 'GET_FLAVOUR',
  GET_METADATA: 'GET_METADATA',
  GET_PLANS: 'GET_PLANS',
  GET_PLAN: 'GET_PLAN',
  GET_PLAN_FILTERS: 'GET_PLAN_FILTERS',
  GET_ASSETS_META: 'GET_ASSETS_META',
  GET_ASSETS_COUNT: 'GET_ASSETS_COUNT',
  GET_ASSETS_PAGE: 'GET_ASSETS_PAGE',
  GET_ASSETS_COUNT_EXCLUDED: 'GET_ASSETS_COUNT_EXCLUDED',
  GET_ASSETS_PAGE_EXCLUDED: 'GET_ASSETS_PAGE_EXCLUDED',
  GET_ASSETS_LOADED: 'GET_ASSETS_LOADED',
  GET_NEW_POI_DATASET_FORM: 'GET_NEW_POI_DATASET_FORM',
  GET_SUBPLAN_FILTERS_INDEX: 'GET_SUBPLAN_FILTERS_INDEX',
  GET_AUDIENCE_DELIVERY: 'GET_AUDIENCE_DELIVERY',
  GET_LEGEND_FILTER: 'GET_LEGEND_FILTER',
  GET_ASSETS_FINISHED_LOAD: 'GET_ASSETS_FINISHED_LOAD',
  GET_EXCLUDED_ASSET_LAYER_AVAILABILITY:
    'GET_EXCLUDED_ASSET_LAYER_AVAILABILITY',
  GET_GEOGRAPHICAL_DISTRIBUTION_REGIONS:
    'GET_GEOGRAPHICAL_DISTRIBUTION_REGIONS',
  GET_IS_LOADING_GEOGRAPHICAL_DISTRIBUTION_REGIONS:
    'GET_IS_LOADING_GEOGRAPHICAL_DISTRIBUTION_REGIONS',
  GET_ASSETS_DETAILS: 'GET_ASSETS_DETAILS',
  GET_DEFAULT_USER_COUNTRIES: 'GET_DEFAULT_USER_COUNTRIES',
  GET_ASSETS_READY_WITH_ALL_PROPERTIES: 'GET_ASSETS_READY_WITH_ALL_PROPERTIES',
  GET_PACKAGES_DATA: 'GET_PACKAGES_DATA',
  GET_PACKAGES_PRICE_RANGE: 'GET_PACKAGES_PRICE_RANGE',
  GET_PACKAGES_DATE_RANGE: 'GET_PACKAGES_DATE_RANGE',
  GET_PACKAGE_PRICE: 'GET_PACKAGE_PRICE',
  GET_PACKAGE_STATE: 'GET_PACKAGE_STATE',
  GET_PACKAGE: 'GET_PACKAGE',
  GET_HIGHLIGHTED_ASSETS: 'GET_HIGHLIGHTED_ASSETS',
  GET_PACKAGES_FILTER: 'GET_PACKAGES_FILTER',
  GET_FORCE_LOGOUT: 'GET_FORCE_LOGOUT',
  GET_POIS_BUFFER_VISIBILITY: 'GET_POIS_BUFFER_VISIBILITY',
  GET_POIS_VISIBILITY: 'GET_POIS_VISIBILITY',
  GET_BOUNDARIES_VISIBILITY: 'GET_BOUNDARIES_VISIBILITY',
  GET_ASSETS_IDS_LAYER_VISIBILITY: 'GET_ASSETS_IDS_LAYER_VISIBILITY',
  GET_COLORIZE_ASSETS: 'GET_COLORIZE_ASSETS',
  IS_TRANSITION_BETWEEN_BASEMAPS: 'IS_TRANSITION_BETWEEN_BASEMAPS',
  GET_ASSETS_ANGLE_NORTH_MAP: 'GET_ASSETS_ANGLE_NORTH_MAP',
  GET_HEATMAP_ASSETS_VISIBILITY: 'GET_HEATMAP_ASSETS_VISIBILITY',
  GET_HEATMAP_ASSETS_TYPE: 'GET_HEATMAP_ASSETS_TYPE',
}

export const MUTATIONS = {
  SAVE_PLAN: 'SAVE_PLAN',
  SAVE_PLAN_FILTER: 'SAVE_PLAN_FILTER',
  SAVE_PLAN_AIDA_ID: 'SAVE_PLAN_AIDA_ID',
  SET_ASSETS: 'SET_ASSETS',
  SET_ASSETS_EXCLUDED: 'SET_ASSETS_EXCLUDED',
  SET_ASSETS_INITIAL_STATS: 'SET_ASSETS_INITIAL_STATS',
  SET_ASSETS_TOTAL_STATS: 'SET_ASSETS_TOTAL_STATS',
  RESET_PLAN: 'RESET_PLAN',
  SET_PLAN_BACKUP: 'SET_PLAN_BACKUP',
  SET_OLD_DIRTY_PLAN: 'SET_OLD_DIRTY_PLAN',
  SET_ASSETS_LOADING: 'SET_ASSETS_LOADING',
  SET_GEOGRAPHICAL_DISTRIBUTION_REGIONS:
    'SET_GEOGRAPHICAL_DISTRIBUTION_REGIONS',
  SET_IS_LOADING_GEOGRAPHICAL_DISTRIBUTION_REGIONS:
    'SET_IS_LOADING_GEOGRAPHICAL_DISTRIBUTION_REGIONS',
  SET_PLAN_LOADING: 'SET_PLAN_LOADING',
  SET_VIEWSTATE: 'SET_VIEWSTATE',
  SET_MAPUPDATEFLAGS: 'SET_MAPUPDATEFLAGS',
  SET_PROXIMITYFLAGS: 'SET_PROXIMITYFLAGS',
  RESET_PROXIMITYFLAGS: 'RESET_PROXIMITYFLAGS',
  REMOVE_CUSTOM_GEOMETRY: 'REMOVE_CUSTOM_GEOMETRY',
  SET_OPEN_GEOMETRY: 'SET_OPEN_GEOMETRY',
  SET_NEW_POI_DATASET_FORM: 'SET_NEW_POI_DATASET_FORM',
  SET_DISTRIBUTION: 'SET_DISTRIBUTION',
  MODIFY_PLAN_SUBFILTER_TABNAME: 'MODIFY_PLAN_SUBFILTER_TABNAME',
  ADD_SUBPLAN_FILTERS: 'ADD_SUBPLAN_FILTERS',
  SET_SUBPLAN_FILTERS_INDEX: 'SET_SUBPLAN_FILTERS_INDEX',
  REMOVE_SUBPLAN_FILTERS: 'REMOVE_SUBPLAN_FILTERS',
  SET_AUDIENCE_DELIVERY: 'SET_AUDIENCE_DELIVERY',
  UPDATE_PROXIMITY_DATA: 'UPDATE_PROXIMITY_DATA',
  UPDATE_LEGEND_FILTER: 'UPDATE_LEGEND_FILTER',
  SET_ASSETS_FINISHED_LOAD: 'SET_ASSETS_FINISHED_LOAD',
  SET_EXCLUDED_ASSET_LAYER_AVAILABLE: 'SET_EXCLUDED_ASSET_LAYER_AVAILABLE',
  SET_ASSETS_DETAILS: 'SET_ASSETS_DETAILS',
  SET_DEFAULT_USER_COUNTRIES: 'SET_DEFAULT_USER_COUNTRIES',
  SET_ASSETS_READY_WITH_ALL_PROPERTIES: 'SET_ASSETS_READY_WITH_ALL_PROPERTIES',
  SET_PACKAGES_DATA: 'SET_PACKAGES_DATA',
  UPDATE_PACKAGES_FILTER: 'UPDATE_PACKAGES_FILTER',
  SET_PACKAGES_PRICE_RANGE: 'SET_PACKAGES_PRICE_RANGE',
  SET_PACKAGE_PRICE: 'SET_PACKAGES_PRICE',
  SET_PACKAGES_DATE_RANGE: 'SET_PACKAGES_DATE_RANGE',
  SET_PACKAGE: 'SET_PACKAGE',
  SET_PACKAGE_STATE: 'SET_PACKAGE_STATE',
  SET_PACKAGE_AVAILABILITY: 'SET_PACKAGE_AVAILABILITY',
  SET_ASSETS_FACING_ORIENTATION_ICONS: 'SET_ASSETS_FACING_ORIENTATION_ICONS',
  SET_POIS_VISIBILITY: 'SET_POIS_VISIBILITY',
  SET_BOUNDARIES_VISIBILITY: 'SET_BOUNDARIES_VISIBILITY',
  SET_ASSETS_IDS_LAYER_VISIBILITY: 'SET_ASSETS_IDS_LAYER_VISIBILITY',
  SET_COLORIZE_ASSETS: 'SET_COLORIZE_ASSETS',
  SET_POIS_BUFFER_VISIBILITY: 'SET_POIS_BUFFER_VISIBILITY',
  SET_HIGHLIGHTED_ASSETS: 'SET_HIGHLIGHTED_ASSETS',
  SET_FORCE_LOGOUT: 'FORCE_LOGOUT',
  SET_POI_FREE_COLOR: 'SET_POI_FREE_COLOR',
  CLEAR_POI_COLOR: 'CLEAR_POI_COLOR',
  CLEAR_POIS_COLOR: 'CLEAR_POIS_COLOR',
  SET_PLAN_DIRTY: 'SET_PLAN_DIRTY',
  SET_BASEMAP: 'SET_BASEMAP',
  SET_TRANSITION_BETWEEN_BASEMAPS: 'SET_TRANSITION_BETWEEN_BASEMAPS',
  SET_ASSETS_ANGLE_NORTH_MAP: 'SET_ASSETS_ANGLE_NORTH_MAP',
  SET_HEATMAP_ASSETS_VISIBILITY: 'SET_HEATMAP_ASSETS_VISIBILITY',
  SET_HEATMAP_ASSETS_TYPE: 'SET_HEATMAP_ASSETS_TYPE',
}
