import { AssetFilterGeoboundaries } from './geoboundary.type'
import { FilterByPoisParamsRequest } from './poi.type'
import { FeatureCollection } from '@turf/turf'

export interface exportAssetsAudienceRequest {
  delivery: string
  index: number | number[]
  asset_ids: string[]
  audience_ids: string[]
}

export interface audienceData {
  class: string
  index: number
}

export interface exportAssetAudience {
  asset_id: string
  audiences_values: audienceData[]
}

export interface exportAssetsPOIsRequest extends FilterByPoisParamsRequest {
  asset_ids: string[]
  geoboundaries?: AssetFilterGeoboundaries
  polygonGeom?: FeatureCollection
}

export interface exportAssetPOI {
  asset_id: string
  id: string
  name: string
  distance: number
  address: string
  zip_code: string
}

export interface exportBasicPOI {
  id: string
  name: string
  address: string
  zip_code: string
}

export interface exportWithinPOI {
  id: string
  name: string
  address: string
  zip_code: string
  within: string
}

export interface exportAudienceLastDate {
  lastDate: string
}

export enum FilePOIColumns {
  Id = 'EXPORT_POI_ID',
  Name = 'EXPORT_POI_NAME',
  Distance = 'EXPORT_POI_DISTANCE',
  Address = 'EXPORT_POI_ADDRESS',
  ZipCode = 'EXPORT_POI_POSTAL_CODE',
}

export enum POIsPartialQueryAim {
  GeoboundariesWithOutGeometry = '',
  GeoboundariesWithGeometry = '_geoboundaries_with_geometry',
  CustomGeoboundaries = '_custom_geoboundaries',
}

export enum CTEsNameForPOIsGrouping {
  GeoboundaryWithOutGeometry = `all_pois`,
  GeoboundaryWithGeometry = `regions_geoboundaries_with_geometry`,
  CustomGeoboundaries = `regions_custom_geoboundaries`,
  CombinateGeoboundaries = 'union_of_all_pois',
}

export interface exportAssetsPOIsDistance {
  internalPanelId: string
  externalPanelId: string
  poiId: string
  poiName: string
  poiAddress: string
  poiCity: string
  poiPostalCode: string
  distance: number
}
